import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Dots } from 'app/components/Common/Dots'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'

export interface ItemProps {
  image?: ImageProps
  title?: string
}

export interface Props {
  items?: ItemProps[]
  title?: string
}

export const BlockServices = memo(function BlockServices({
  title,
  items,
}: Props) {
  return (
    <Container>
      <StyledDots width={18} height={6} />
      <Wrapper>
        {title ? (
          <FadeInUp>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
          </FadeInUp>
        ) : null}
        {items ? (
          <Items>
            {items.map((item, index) => (
              <Item key={index}>
                <FadeInUp delay={index * 0.1}>
                  {item.image ? (
                    <Inner>
                      <StyledImage {...item.image} />
                    </Inner>
                  ) : null}
                  {item.title ? <ItemTitle>{item.title}</ItemTitle> : null}
                </FadeInUp>
              </Item>
            ))}
          </Items>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  margin: 0 10vw;
  padding-top: 5.55vw;
  text-align: center;

  @media (max-width: 991px) {
    margin: 0 30px;
    padding-top: 30px;
  }
`

const StyledDots = styled(Dots)`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate3d(-50%, 0, 0);
  padding-left: 2.77vw;
`

const Wrapper = styled.div`
  position: relative;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 2.22vw;
  line-height: 1.3125;

  @media (max-width: 991px) {
    font-size: 22px;
  }
`

const Items = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2.77vw;
  margin-left: -7.9vw;
  margin-bottom: -5vw;

  @media (max-width: 991px) {
    margin-top: 20px;
    margin-left: -30px;
    margin-bottom: 30px;
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(25% - 7.9vw);
  margin-left: 7.9vw;
  margin-bottom: 5vw;

  @media (max-width: 991px) {
    width: calc(50% - 30px);
    margin-left: 30px;
    margin-bottom: 30px;
  }
`

const ItemTitle = styled.div`
  margin-top: 2.08vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.69vw;
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1439px) {
    font-size: 10px;
  }
`

const Inner = styled.div`
  position: relative;
  height: 14.02vw;
  aspect-ratio: 1;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 50%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.variants.neutralLight3};
    border-radius: 100% 0 0 100%/50% 0 0 50%;
  }

  @media (max-width: 991px) {
    display: inline-block;
    height: 25vw;
  }
`

const StyledImage = styled(Image)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  height: 11.11vw;
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate3d(-50%, -50%, 0);
  }

  @media (max-width: 991px) {
    height: 20vw;
  }
`
